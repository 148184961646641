import "../styles/helpers/resetNew.scss"
import "../styles/globals.scss"
import "../styles/helpers/animations.scss"
import "../styles/helpers/moving-objects.scss"

import "../styles/ui/style.scss"
import "../styles/ui/header.scss"

import "../styles/welcome.scss"

import * as amplitude from "@amplitude/analytics-browser"
import config from "../config"

amplitude.init(config.services.amplitude.key, {
	defaultTracking: true,
})

window.onscroll = function () {
	let header = document.getElementById("header")
	if (header) {
		if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
			header.classList.add("scrolled")
		} else {
			header.classList.remove("scrolled")
		}
	}
}

const scrollElements = document.querySelectorAll(".reveal")

let options = {
	threshold: 0, //0.1
}
const observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			entry.target.classList.add("animated")
			observer.unobserve(entry.target)
		}
	})
}, options)

document.addEventListener("DOMContentLoaded", function () {
	amplitude.track("TOON.ORG_pageLoad")

	scrollElements.forEach((el) => observer.observe(el))
})

document.getElementById("mainButton").addEventListener("click", function () {
	amplitude.track("mainBotClick")
})

document.getElementById("bitcoinButton").addEventListener("click", function () {
	amplitude.track("bitcoinBotClick", { source: "Create now! (button)" })
})

document.getElementById("wrappedButton").addEventListener("click", function () {
	amplitude.track("wrappedClick")
})

document.getElementById("earlyButton").addEventListener("click", function () {
	amplitude.track("earlyaccessClick")
})

document.getElementById("bitcoinButtonText").addEventListener("click", function () {
	amplitude.track("bitcoinBotClick", { source: "BitcoinArtBot on Telegram (paragraph link)" })
})

window.addEventListener("load", function () {
	adjustMaxWidth()

	window.addEventListener("resize", function () {
		adjustMaxWidth()
	})
})

function adjustMaxWidth() {
	var windowWidth = window.innerWidth
	var parentContainer = document.querySelector(".main-sections")

	if (windowWidth < 500) {
		parentContainer.style.maxWidth = windowWidth + "px"
	} else {
		parentContainer.style.maxWidth = ""
	}
}
